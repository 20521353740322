












































































































import Vue from 'vue';
import { enums } from '@truck-parking/tp-api';
import Timer from '../components/Timer.vue';

export default Vue.extend({
  name: 'EntryGate',
  components: { Timer },
  props: {
    plate: {
      type: String,
      default(): string {
        return '';
      },
    },
    newVehicle: {
      type: String,
      default(): string {
        return '';
      },
    },
  },
  data() {
    return {
      loading: true,
      parkingAllowedAt: null as string | null,
      parkingAllowed: false,
      startTime: null as string | null,
      reason: '',
      timerExpired: false,
    };
  },
  mounted() {
    this.$api.getStatus().then(({ userStatus }) => {
      this.parkingAllowed = userStatus.status === enums.UserStatus.Idle;
      this.parkingAllowedAt = userStatus.nextParkingAllowed;
      if (userStatus.status === enums.UserStatus.Idle && this.plate === '') {
        // Redirect to welcome page if no license plate given
        this.$changeRoute('VehicleDetails');
        this.$toaster.warning(this.$i18n.t('entryGate-noLicensePlate').toString());
      }
      if (userStatus.status === enums.UserStatus.Cooldown) {
        this.reason = 'entryGate-cooldown';
        this.$api.getParkingEvents().then(({ body: pe }) => {
          this.startTime = pe.parkingEvents[0].endDateTime;
        });
      }
      if (userStatus.status === enums.UserStatus.Banned) {
        this.reason = 'entryGate-ban';
        this.$api.getCurrentBans().then(({ body: bans }) => {
          // If user status is banned and no current bans, something weird happened
          if (bans.currentBans.length === 0) {
            this.$toaster.error(this.$i18n.t('global-unexpectedError').toString());
          } else {
            this.startTime = bans.currentBans[0].startDateTime;
          }
        });
      }

      this.loading = false;
    });
  },
  methods: {
    sendNewParkingEvent() {
      this.$api.newParkingEvent({ licensePlate: this.plate })
        .then(
          // on success
          () => this.$changeRoute('Parking'),
          () => { /* on failure */ },
        );
    },
  },
});
